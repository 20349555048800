import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { policy } from '../Information'
import styles from './Privacy.module.css'
import InformativaPrivacy from './InformativaPrivacy'
import { AiOutlineClose } from 'react-icons/ai'

const privacyVariant = {
    hidden: {
        y: 500,
        opacity: 0,
        transition: {
            duration: 1,
            ease: 'easeInOut'
        }
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            delay: 2,
            duration: 1,
            ease: 'easeInOut'
        }
    }
}

const backdrop = {
    hidden: { opacity: 0, zIndex: -1 },
    visible: { opacity: 1, zIndex: 100 }
}

const modal = {
    hidden: { y: "-100vh", opacity: 0 },
    visible: {
        y: "100px",
        opacity: 1,
        transition: { delay: 0.5 }
    },
}


function Privacy({ cookies, setCookie, language }) {

    const [isOpen, setOpen] = useState(false)


    return (
        <>
            <motion.div
                initial='hidden'
                animate={cookies ? 'hidden' : 'visible'}
                exit='hidden'
                variants={privacyVariant}
                className={styles.container}
            >
                <div className={styles.wrapper}>
                    <div style={{ fontSize: '16px' }}>
                        <div dangerouslySetInnerHTML={{ __html: policy.policy[language] }} />

                    </div>
                    <button
                        className={styles.btnInfo}
                        onClick={() => { setOpen(!isOpen) }}>
                        {policy.btnInfo[language]}
                    </button>

                    <button
                        className={styles.btn}
                        onClick={() => { setCookie('cookiePolicy', true) }}>
                        {policy.btn[language]}
                    </button>
                </div>

            </motion.div>


            <ModalInfoPrivacy
                isOpen={isOpen}
                setOpen={setOpen}
                setCookie={setCookie}
                language={language}
            />


        </>
    )
}

export default Privacy


const ModalInfoPrivacy = ({ isOpen, setCookie, setOpen, language }) => {
    return (
        <motion.div className={styles.backdrop}
            variants={backdrop}
            initial='hidden'
            animate={isOpen ? 'visible' : 'hidden'}
            exit='hidden'
        >
            <motion.div
                className={styles.modal}
                variants={modal}
            >
                <div>
                    <AiOutlineClose
                        className={styles.btnClose}
                        onClick={() => { setOpen(!isOpen) }}
                    />
                </div>
                <div className={styles.normativaContainer}>
                    <InformativaPrivacy />
                </div>
                <button
                    className={styles.btnInfo}
                    style={{ borderColor: 'black', color: 'black' }}
                    onClick={() => { setOpen(!isOpen) }}>
                    {policy.close[language]}
                </button>
                <button
                    className={styles.btn}
                    onClick={() => { setCookie('cookiePolicy', true) }}>
                    {policy.btn[language]}
                </button>

            </motion.div>
        </motion.div>
    )
}

