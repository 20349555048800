import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { multiLanguageBooking } from '../../Information'
import EmailForm from '../Contacts/EmailForm'
import styles from './Booking.module.css'
import { Link, useLocation } from 'react-router-dom'
import MotionHoC from '../../utility/MotionHoC'


function BookingComponent({ language }) {

    const location = useLocation()
    const [room, setRoom] = useState(null)

    useEffect(() => {
        if (location.state?.room)
            setRoom(location.state.room);

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [location.state])



    return (
        <div className={styles.container}>
            <div className={`title is-1 ${styles.title}`} children={multiLanguageBooking.book[language]} />
            <div className={`subtitle ${styles.text}`} children={multiLanguageBooking.bookText[language]} />



            <div style={{ marginTop: '10vh', }}>
                {<PriceTable language={language} />}
                <EmailForm language={language} booking={true} room={room} />
            </div>
        </div>
    )
}

const Booking = MotionHoC(BookingComponent)

export default Booking


function PriceTable({ language }) {
    return (
        <>
            <div className={`subtitle ${styles.text}`} style={{ color: 'red', fontStyle: 'italic', textAlign: 'center' }} children={<div dangerouslySetInnerHTML={{ __html: multiLanguageBooking.price[language] }} />} />
            <div className={styles.text} style={{ color: 'red', opacity: 0.6, fontStyle: 'italic', textAlign: 'center' }} children={<div dangerouslySetInnerHTML={{ __html: multiLanguageBooking.priceAdvisor[language] }} />} />
        </>
    )
    return (
        <div className='columns is-vcentered is-centered' style={{ margin: 'auto', textAlign: 'center' }}>
            <table className="column table is-hoverable is-centered " style={{ margin: 'auto', color: 'green', }}>
                <thead>
                    <tr>
                        <th>BASSA STAGIONE </th>
                        <th>
                            01/11 - 20/12
                            <br />
                            07/01 - 30/04
                        </th>
                    </tr>
                </thead>
                <thead>
                    <tr>
                        <th>Camera</th>
                        <th>Prezzo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Coccinella</th>
                        <td>110€</td>
                    </tr>
                    <tr>
                        <th>Farfalla</th>
                        <td>100€</td>
                    </tr>
                    <tr>
                        <th>Gufo</th>
                        <td>100€</td>
                    </tr>
                </tbody>
            </table>
            <table className="column table is-hoverable is-centered " style={{ margin: 'auto', color: 'orange' }}>
                <thead>
                    <tr>
                        <th>MEDIA STAGIONE </th>
                        <th>
                            01/05 - 30/06
                            <br />
                            01/09 - 31/10
                        </th>
                    </tr>
                    <tr>
                        <th>Camera</th>
                        <th>Prezzo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Coccinella</th>
                        <td>120€</td>
                    </tr>
                    <tr>
                        <th>Farfalla</th>
                        <td>110€</td>
                    </tr>
                    <tr>
                        <th>Gufo</th>
                        <td>110€</td>
                    </tr>
                </tbody>
            </table>
            <table className="column table is-hoverable" style={{ margin: 'auto', color: 'red' }}>
                <thead>
                    <tr>
                        <th>ALTA STAGIONE </th>
                        <th>
                            01/07 - 31/08
                            <br />
                            20/12 - 06/01
                        </th>
                    </tr>
                    <tr>
                        <th>Camera</th>
                        <th>Prezzo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Coccinella</th>
                        <td>140€</td>
                    </tr>
                    <tr>
                        <th>Farfalla</th>
                        <td>120€</td>
                    </tr>
                    <tr>
                        <th>Gufo</th>
                        <td>120€</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export function BookingButton({ language, room }) {

    const btnVariant = {
        hidden: {
            transition: {
                duration: 1,
            }
        },
        visible: {
            scale: [1, 1.5, 1.5, 1, 1],
            /* rotate: [0, 0, 20, -20, 0], */
            borderRadius: ["20%", "20%", "50%", "50%", "20%"],
            transition: {
                repeat: Infinity,
                duration: 6,
                repeatType: "reverse",
            }
        },
        hover: {
            scale: 1.2,
            rotate: 0,
            transition: {
                ease: 'easeInOut',
                duration: 1,
                type: 'spring',
                stiffness: 250
            }
        }
    }

    return (
        <motion.div
            initial='hidden'
            animate='visible'
            whileHover='hover'
            variants={btnVariant}
            className={styles.btnContainer}
        >
            <Link
                className={`button subtitle is-3 ${styles.button}`}
                children={multiLanguageBooking.book[language]}
                to={{
                    pathname: "/prenota",
                    state: {
                        room: room,
                    },
                }}
            />
        </motion.div>
    )
}



