import React from 'react';
import NavMobile from './NavMobile.js';
import NavDesk from './NavDesk.js';
import { IoIosHome, IoIosBed, IoIosCall } from 'react-icons/io'
import { RiLuggageCartFill } from 'react-icons/ri'
import { multiLanguageNavbar } from '../../Information'


export const NavItem = [
    {
        text: multiLanguageNavbar.home,
        icon: <IoIosHome />,
        link: '/'
    },
    {
        text: multiLanguageNavbar.room,
        icon: <IoIosBed />,
        link: '/camere'
    },
    {
        text: multiLanguageNavbar.contact,
        icon: <IoIosCall />,
        link: '/contatti'
    },
    {
        text: multiLanguageNavbar.booking,
        icon: <RiLuggageCartFill />,
        link: '/prenota'
    }
]


function Navbar({ mobileView, language }) {
    if (mobileView) {
        return (
                <NavMobile language={language} location={window.location.pathname} />
        )
    }
    else {
        return (
            <NavDesk language={language} location={window.location.pathname} />
        )
    }
}

export default Navbar
