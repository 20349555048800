import { motion } from 'framer-motion';
import React from 'react'
import Ticker from 'react-ticker'
import { useHover } from '../../utility/customHook';
import styles from './TickerSlide.module.css'
import { information } from '../../Information';

const TickerItem = [
    /* {
        link: information.AIRBNB,
        logo: '/images/ticker/Airbnb.png'
    }, */
    {
        link: information.BOOKING,
        logo: '/images/ticker/Booking.png'
    },
    /* {
        link: information.TRIPADVISOR,
        logo: '/images/ticker/TripAdvisor.png'
    }, */
    {
        link: information.FACEBOOK,
        logo: '/images/ticker/Facebook-logo.png'
    },
    {
        link: information.INSTAGRAM,
        logo: '/images/ticker/instagram.png'
    },

]

function TickerSlide() {
    const [hoverRef, isHovered] = useHover();

    return (
        <div ref={hoverRef} className={styles.container}>
            <Ticker
                offset="run-in"
                move={!isHovered}
                
            >
                {({ index }) => {
                    
                    return (
                    <motion.div className={styles.itemContainer} whileHover={{scale : 1.2}} > 
                        <figure className={`${styles.figure}`} onClick={() => window.open(TickerItem[index % TickerItem.length]?.link)} >
                            <img className={styles.img} src={TickerItem[index % TickerItem.length]?.logo} alt='6' />
                        </figure>

                    </motion.div>
                )}}
            </Ticker>
        </div>
    )
}

export default TickerSlide
