import React from 'react'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import styles from './LanguageDropDown.module.css'
import { motion } from 'framer-motion'
import { useHover } from '../../utility/customHook';


const Flags = [
    {
        value: 'it',
        key: '🇮🇹'
    },
    {
        value: 'gb',
        key: '🇬🇧'
    },
    /* {
        value: 'rus',
        key: getUnicodeFlagIcon('rus')
    },
    {
        value: 'es',
        key: getUnicodeFlagIcon('es')
    },
    {
        value: 'de',
        key: getUnicodeFlagIcon('de')
    },
    {
        value: 'fr',
        key: getUnicodeFlagIcon('fr')
    }, */

]



/* VARIANTS */
const dropDownVariant = {
    open: {
        x: 0,
        transition: { staggerChildren: 0.2, delayChildren: 0.2 }
    },
    closed: {
        x: -500,
        transition: {
            staggerChildren: 0.08,
            staggerDirection: -1,
            delay: 0.5,

        }
    }
};

const itemVariant = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 }
        }
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 }
        }
    },
};


function LanguageDropDown() {

    const [hover, isHovered] = useHover(false);

    return (
        <>
            <motion.div
                className={styles.dropdown}
                ref={hover}
            >
                <motion.div
                    initial={{ skew: -7 }}
                    animate={{ skew: 7 }}
                    transition={{ repeat: Infinity, duration: 2, repeatType: "reverse", }}
                    className={styles.dropbtn}
                    children={getUnicodeFlagIcon(sessionStorage.getItem('language') ? sessionStorage.getItem('language') : 'it')} />
                <motion.ul
                    className={`${styles.content}`}
                    initial='closed'
                    animate={isHovered ? 'open' : 'closed'}
                    variants={dropDownVariant}
                >
                    {Flags.map((item, index) => {
                        if (item.value !== sessionStorage.getItem('language')) {
                            return (
                                <motion.li
                                    className={styles.item}
                                    key={index}
                                    variants={itemVariant}
                                    whileHover={{ scale: 1.1, transition: { duration: .3, ease: 'easeInOut' } }}
                                    onClick={() => {
                                        sessionStorage.setItem('language', item.value);
                                        window.location.reload();
                                    }}
                                    id={item.value}

                                >
                                    <motion.div
                                        initial={{ skew: -7 }}
                                        animate={{ skew: 7 }}
                                        transition={{ repeat: Infinity, duration: 2, repeatType: "reverse", }}
                                        children={item.key} />



                                </motion.li>
                            );
                        }
                        return null;
                    })}
                </motion.ul>
            </motion.div>
        </>
    );
}

export default LanguageDropDown

//l'errore e' nel styles.content, se lo risolvo le vedo in fila le bandiere

export function LanguageMobile({click}) {

    return (
        <>
            <motion.div className={styles.dropdown}>
                <motion.div
                    className={styles.dropbtn}
                    children={getUnicodeFlagIcon(sessionStorage.getItem('language') ? sessionStorage.getItem('language') : 'it')} />
                <motion.ul
                    className={`${styles.content}`}
                    initial='closed'
                    animate={click ? 'open' : 'closed'}
                    variants={dropDownVariant}
                >
                    {Flags.map((item, index) => {
                        if (item.value !== sessionStorage.getItem('language')) {
                            return (
                                <motion.li
                                    className={styles.item}
                                    key={index}
                                    variants={itemVariant}
                                    onClick={() => {
                                        sessionStorage.setItem('language', item.value);
                                        window.location.reload();
                                    }}
                                    id={item.value}

                                >
                                    <motion.div children={item.key} />
                                </motion.li>
                            );
                        }
                        return null;
                    })}
                </motion.ul>
            </motion.div>
        </>
    );
}


