import React, { useState, useEffect } from 'react'
import styles from './RoomsCard.module.css'
import { multiLanguageRoom } from '../../Information'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'


const btnVariant = {
    hidden: {
        opacity: 0,
        transition: {
            duration: 0.5,
            delay: 1.5,
            ease: 'easeInOut'
        }
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: 'easeInOut'
        }
    }
}

const cardVariant = {
    hidden: {
        opacity: 0,
        transition: {
            duration: 0.5,
            delay: 1.5,
            ease: 'easeInOut'
        }
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: 'easeInOut',
            type: 'spring',
            stiffness: 200,
        }

    },
    hover: {
        scale: 1.05,
        transition: {
            duration: 0.5,
            ease: 'easeInOut',
            type: 'spring',
            stiffness: 200,
        }
    }

}

const iconVariant = {
    hidden: {
        opacity: 1,
        y: 50
    },
    visible: {
        y: 50,
        opacity: 1,
        scale: 1.3,
        transition: {
            repeat: Infinity,
            duration: .3,
            repeatType: "reverse",
        }
    }
}


function RoomsCard(props) {

    const [list, setList] = useState([]);
    const [range, setRange] = useState([0, 6]);
    const [start, end] = range;

    useEffect(() => {
        if (props.service?.length > 5) {
            setList(props.service.slice(start, end))
        }
        else {
            setList(props.service)
        }
    }, [range, start, end, setList, props.service])


    return (
        <motion.div
            className={`card ${styles.card}`}
            initial='hidden'
            animate='visible'
            whileHover='hover'
            variants={cardVariant}>
            <div className="card-image">
                <figure className="image is-3by2">
                    <img src={props.img_URL} alt={'0'} />
                </figure>
            </div>
            <div className="card-content p-3 " >
                <span className={`${styles.subTitle} title is-2`}>{props.title?.length > 40 ? props.title.substring(0, 40) + '...' : props.title}</span>
            </div>
            <motion.div className="card-content-padding p-1">
                <motion.div
                    className={styles.back}
                    initial='hidden'
                    animate='visible'
                    whileHover='hover'
                    variants={iconVariant}>
                    {start > 0 ? <IoIosArrowBack className={styles.icon} onClick={() => { setRange([start - 6, end - 6]) }} /> : ''}
                </motion.div>
                <motion.div className={styles.forward}
                    initial='hidden'
                    animate='visible'
                    whileHover='hover'
                    variants={iconVariant}>
                    {props.service.length > end ? <IoIosArrowForward className={styles.icon} onClick={() => { setRange([start + 6, end + 6]) }} /> : ''}
                </motion.div>
                <div className={`${styles.service} content columns is-multiline is-mobile`}>
                    {
                        list.map((item, i) => (
                            <div className='column is-half-mobile is-half-tablet is-half-desktop is-one-third-widescreen is-one-third-fullhd' key={i}>
                                {item.icon} {'\t'}
                                {item.name[props.language]}
                            </div>
                        ))
                    }
                </div>

            </motion.div>
            <div className={`card-footer-padding ${styles.btnContainer}`}>
                <Link to={`/room/${props.title}`}>
                    <motion.button
                        initial='hidden'
                        animate='visible'
                        variants={btnVariant}
                        className={`button ${styles.button}`}
                        children={multiLanguageRoom.show[props.language]}

                    />
                </Link>
            </div>
        </motion.div >

    )
}

export default RoomsCard
