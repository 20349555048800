import React, { useState } from 'react'
import styles from './Formik.module.css';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';
import DateView from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'



export function Input(props) {
    const { label, name, ...rest } = props


    return (
        <div className={styles.formControl}>
            <label htmlFor={name} className={styles.label}>{label}</label>
            <Field id={name} name={name} {...rest} />
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export function TextArea(props) {
    const { label, name, ...rest } = props

    return (
        <div className={styles.formControl}>
            <label htmlFor={name} className={styles.label}>{label}</label>
            <Field as='textarea' id={name} name={name} {...rest} />
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export function Select(props) {

    const { label, name, options, ...rest } = props

    return (
        <div className={styles.formControl}>
            <label htmlFor={name} className={styles.label}>{label}</label>
            <Field as='select' id={name} name={name} {...rest}>
                {
                    options.map(option => {
                        return (
                            <option key={option.value} value={option.value}  style={{ color: 'black' }} >
                                {option.key}
                            </option>
                        )
                    })
                }
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div >
    )
}

export function RadioButtons(props) {
    const { label, name, options, ...rest } = props

    return (
        <div className={styles.formControl}>
            <label htmlFor={name}>{label}</label>
            <Field as='select' id={name} name={name} {...rest}>
                {
                    ({ field }) => {
                        return options.map(option => {
                            return (
                                < React.Fragment key={option.key}>
                                    <input type='radio'
                                        id={option.value}
                                        {...field}
                                        value={option.value}
                                        checked={field.value === option.value}
                                    />
                                    <label htmlFor={option.value}>{option.key}</label>
                                </ React.Fragment>
                            )
                        })
                    }

                }
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}


export function CheckboxGroup(props) {
    const { label, name, options, ...rest } = props

    return (
        <div className={styles.formControl}>
            <label htmlFor={name}>{label}</label>
            <Field name={name} {...rest}>
                {
                    ({ field }) => {
                        return options.map(option => {
                            return (
                                < React.Fragment key={option.key}>
                                    <input
                                        type='checkbox'
                                        id={option.value}
                                        {...field}
                                        value={option.value}
                                        checked={field.value?.includes(option.value)}
                                    />
                                    <label htmlFor={option.value}>{option.key}</label>
                                </ React.Fragment>
                            )
                        })
                    }

                }
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}


export function DatePicker(props) {

    const { label, name, checkIn, ...rest } = props;
    var today = new Date();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    return (
        <div className={styles.formControl}>
            <label htmlFor={name} className={styles.label}>{label}</label>
            <Field name={name}>
                {
                    ({ form, field }) => {
                        const { setFieldValue } = form

                        return (
                            <DateView
                                id={name}
                                {...field}
                                {...rest}
                                selected={checkIn ? endDate : startDate}
                                onChange={(date) => {
                                    if (checkIn)
                                        setEndDate(date);
                                    else
                                        setStartDate(date);
                                    setFieldValue(name, date)
                                }}
                                selectsStart={checkIn ? false : true}
                                selectsEnd={checkIn ? true : false}
                                startDate={checkIn ? checkIn : startDate}
                                endDate={endDate}
                                closeOnScroll={true}
                                minDate={checkIn ? checkIn : today}
                                showWeekNumbers
                                dateFormat="dd/MM/yyyy"
                            />
                        );

                    }
                }
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

