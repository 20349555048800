import React, { useEffect, useState } from 'react'
import styles from './Slider.module.css'
import Carousel from 'react-multi-carousel';
import { SliderHome, SliderNostri, SliderPosti, SliderReview } from './SliderItem'

function Slider(props) {

    const [sliderOption, setSliderOption] = useState([])

    useEffect(() => {
        setSliderOption(getSliderImage(props.option))
    }, [props])

    const CustomLeftArrow = ({ onClick, ...rest }) => {

        // onMove means if dragging or swiping in progress.
        return <button className={`${styles.arrow} ${styles.arrowLeft}`} onClick={() => onClick()} />;
    };

    const CustomRightArrow = ({ onClick, ...rest }) => {

        // onMove means if dragging or swiping in progress.
        return <button className={`${styles.arrow} ${styles.arrowRight}`} onClick={() => onClick()} />;
    };

    const CustomDot = ({ onClick, ...rest }) => {
        const {
            active,
        } = rest;

        return (
            <button
                className={active ? styles.dotActive : styles.dot}
                onClick={() => onClick()}
            />
        );
    };


    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,

        },
        tablet: {
            breakpoint: { max: 960, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    };

    return (


        <Carousel
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            responsive={responsive}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={15000}
            transitionDuration={500}
            showDots={true}
            customDot={<CustomDot />}
            dotListClass={styles.dotList}
            containerClass={styles.sliderContainer}
            {...props}
        >

            {
                sliderOption.map((slideItem, index) =>
                    <div key={index} >
                        <img className={styles.image} src={slideItem.image} alt='4' style={props.styleOption} onClick={props.onClick} />
                        {/*   <div className={`${styles.textContainer} ${index % 2 === 0 ? styles.right : ''}`} >
                            <div className={`subtitle is-4 ${styles.title}`}>
                                {slideItem.title}
                            </div>
                            <div className={`subtitle ${styles.text}`}>
                                {slideItem.text}
                            </div>
                        </div> */}
                    </div>
                )
            }

        </Carousel >

    )

}

export default Slider


function getSliderImage(option) {
    switch (option) {
        case 'nostri':
            return SliderNostri;
        case 'posti':
            return SliderPosti;
        case 'review':
            return SliderReview;
        default:
            return SliderHome;
    }
}