import React from "react";
import styles from "./Footer.module.css";
import { FaArrowUp, FaPhone, FaAirbnb } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { AiFillFacebook, AiOutlineInstagram } from "react-icons/ai";
import { MdCopyright } from "react-icons/md";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { information, multiLanguageFooter } from "../../Information";
import TickerSlide from "../Home/TickerSlide";

const variants = {
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: { duration: 0.3, ease: "easeInOut" },
  },
  hidden: {
    opacity: 0,
    y: 120,
    transition: { duration: 0.3, ease: "easeInOut" },
  },
};

const iconVariant = {
  hidden: {
    opacity: 1,
    y: -10,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      repeat: Infinity,
      duration: 0.3,
      repeatType: "reverse",
    },
  },
};

function Footer({ language }) {
  var date = new Date();

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.2,
    triggerOnce: false,
  });

  const smoothScroll = (h) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <TickerSlide />
      <motion.div
        className={`footer ${styles.footerContainer}`}
        ref={ref}
        initial={"hidden"}
        animate={inView ? "visible" : "hidden"}
        variants={variants}
      >
        <motion.div
          initial={"hidden"}
          animate={"visible"}
          variants={iconVariant}
          className="content has-text-centered"
        >
          <FaArrowUp
            className={styles.arrowIcon}
            onClick={() => smoothScroll()}
          />
        </motion.div>

        <div className={`columns is-multiline is-mobile `}>
          <div
            className={`column is-full-mobile is-one-third-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd`}
          >
            <Link to="/">
              <img className={styles.logo} src={information.LOGO} alt="1" />
            </Link>
          </div>

          <div
            className={`column is-full-mobile is-one-third-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd`}
          >
            <div
              className={`title ${styles.subTitle}`}
              children={multiLanguageFooter.contact[language]}
            />
            <div className={`columns is-mobile is-multiline subtitle`}>
              <div>
                <a
                  className={`column is-full ${styles.link}`}
                  href={`tel: ${information.PHONE}`}
                >
                  <FaPhone />
                  {information.PHONE}
                </a>
                <a
                  className={`column is-full ${styles.link}`}
                  href={`mailto: ${information.EMAIL}`}
                >
                  <MdEmail />
                  {information.EMAIL}
                </a>
              </div>
            </div>
          </div>

          <div
            className={`column is-full-mobile is-one-third-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd`}
          >
            <div
              className={`title ${styles.subTitle}`}
              children={multiLanguageFooter.follow[language]}
            />
            <div className={`columns is-mobile is-multiline`}>
              <div className={`column is-full subtitle`}>
                <span
                  className={`${styles.link} ${styles.item} subtitle is-3`}
                  onClick={() =>
                    information.FACEBOOK
                      ? window.open(information.FACEBOOK, "_blank")
                      : ""
                  }
                  children={<AiFillFacebook />}
                />
                <span
                  className={`${styles.link} subtitle is-3`}
                  onClick={() =>
                    information.INSTAGRAM
                      ? window.open(information.INSTAGRAM, "_blank")
                      : ""
                  }
                  children={<AiOutlineInstagram />}
                />
              </div>
            </div>
          </div>

          <div
            className={`column is-full-mobile is-two-thirds-tablet is-two-thirds-desktop is-two-thirds-widescreen is-two-thirds-fullhd`}
          >
            <Maps />
          </div>

          <div
            className={`column is-full-mobile is-one-third-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd`}
          >
            <div
              className={`title ${styles.subTitle}`}
              children={multiLanguageFooter.where[language]}
            />
            <div
              className={`subtitle ${styles.item} ${styles.link}`}
              onClick={() =>
                window.open("https://goo.gl/maps/EYMKszDzxr9RnZDU7")
              }
            >
              {information.ADDRESS}
              <div />
              {`${information.CAP} - ${information.CITY}`}
            </div>

            {/* <span className={`${styles.link} ${styles.item} subtitle is-3`}
                            onClick={() => information.AIRBNB ? window.open(information.AIRBNB, "_blank") : ''}
                            children={<img src='/images/logo/airbnb.png' className={styles.icon} alt='' />}
                        /> */}
            <span
              className={`${styles.link} ${styles.item} subtitle is-3`}
              onClick={() =>
                information.BOOKING
                  ? window.open(information.BOOKING, "_blank")
                  : ""
              }
              children={
                <img
                  src="/images/logo/booking.png"
                  alt=""
                  className={styles.icon}
                />
              }
            />
          </div>
        </div>
      </motion.div>

      <motion.div
        initial={"hidden"}
        animate={inView ? "visible" : "hidden"}
        variants={variants}
        className={` ${styles.footerLine}`}
      >
        <div className={styles.firma}>
          {multiLanguageFooter.firma[language]}
          <span
            className={styles.firmaLink}
            children=" Bocchino Daniele"
            onClick={() =>
              window.open("https://danielebocchino.github.io/", "_blank")
            }
          />
        </div>
        <p style={{ textAlign: "center", fontSize:16, paddingBottom:'1rem' }}>
        <MdCopyright style={{fontSize:12}}/> {date.getFullYear()}  {" Lora Giusta "} - { `P.IVA ${information.PIVA} `}
        </p>
      </motion.div>
    </>
  );
}

export default Footer;

export function Maps() {
  return (
    <>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2781.761104656395!2d9.106298015985326!3d45.79601357910632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47869fff84841e0b%3A0xc8af499043eb6df1!2sLora%20Giusta%20Guest%20House!5e0!3m2!1sit!2sit!4v1631346761490!5m2!1sit!2sit"
        loading="lazy"
        title="maps"
        style={{
          border: "none",
          allowfullscreen: "",
          loading: "lazy",
        }}
        className={styles.maps}
      ></iframe>
    </>
  );
}
