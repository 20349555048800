import React, { useEffect, useState } from 'react';
import styles from './Navbar.module.css';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom'
import LanguageDropDown from './LanguageDropDown';
import { NavItem } from './Navbar.js';
import { information } from '../../Information';

const navbarVariant = {
    hidden: {
        opacity: 0,
        x: 0,
        transition: {
            staggerChildren: 1,
            staggerDirection: -1,
            delay: 0.6
        }
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            staggerChildren: .3,
            delayChildren: .2
        }
    }
}

const itemVariant = {
    visible: {
        opacity: 1,
        transition: {
            y: { stiffness: 1000, }
        }
    },
    hidden: {

        opacity: 0,
        transition: {
            y: { stiffness: 1000, }
        }
    },

};


function NavDesk({ language, location }) {

    const [scrolled, setScrolled] = useState(false);
    const [active, setActive] = useState(0);
    const [hover, setHover] = useState({
        hover: true,
        index: null,
    });

    const wrapVariant = {
        hidden: {
            opacity: 0,
            y: -100,
        },
        visible: {
            opacity: 1,
            y: scrolled ? -25 : 0,
            transition: {

                ease: 'easeInOut',
                type: 'spring',
                stiffness: 200,
            },
            scale: scrolled ? 0.6 : 1,
        },

        hover: {
            scale: 1.1,
            transition: {
                ease: 'easeInOut',
            },
        },
        hoverLogo: {
            scale: 0.9,
            y: -10,
            transition: {
                ease: 'easeInOut',
            },
        },
    }

    const iconVariant = {
        hidden: i => ({
            opacity: 1,
            y: i === active ? 15 : 20,
            transition: {
                ease: 'easeInOut',

            },
            fontSize: '50px',
        }),
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                ease: 'easeInOut',
            },
            fontSize: '20px',
        },
    }
    const textVariant = {
        hidden: {
            opacity: 0,
            
            y: 20,
            transition: {
                ease: 'easeInOut',

            },
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                ease: 'easeInOut',
            },
            fontSize: scrolled ? '20px' : '40px',
        },
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    useEffect(() => {
        location && setActive(() => {
            switch (location) {
                case '/':
                    return 0;
                case '/camere':
                    return 1;
                case '/contatti':
                    return 2;
                case '/prenota':
                    return 3;

                default:
                    return 1;
            }
        });

    }, [location])


    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 1) {
            setScrolled(true);
        }
        else {
            setScrolled(false);
        }
    }


    return (
        <motion.ul
            className={scrolled ? `${styles.navbarContainer} ${styles.scrolled}` : `${styles.navbarContainer}`}
            initial='hidden'
            animate='visible'
            variants={navbarVariant}
        >
            <motion.li
                variants={itemVariant}
                className={styles.navItem}>
                <motion.div
                    initial='hidden'
                    animate='visible'
                    whileHover='hoverLogo'
                    variants={wrapVariant}
                >
                    <Link to='/'
                        onClick={() => window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        })}>
                        <img src={information.LOGO} className={styles.logo} alt='7' />
                    </Link>
                </motion.div>


            </motion.li>
            {

                NavItem.map((item, index) => {
                    return (
                        <motion.li
                            key={index}
                            className={styles.navItem}
                            variants={itemVariant}
                            onClick={() => setActive(index)}
                        >
                            <Link style={{ textDecoration: 'none' }} to={item.link}>
                                <motion.div
                                    className={` ${active === index ? `${styles.navWrapActive}` : styles.navWrap} subtitle`}
                                    initial='hidden'
                                    animate={'visible'}
                                    variants={wrapVariant}
                                    whileHover={active === index ? '' : 'hover'}
                                    onHoverStart={() => setHover(s => ({ ...s, hover: true, index: index }))}
                                    onHoverEnd={() => setHover(s => ({ ...s, hover: false, index: null }))}
                                    whileTap={{ scale: 0.5 }}

                                >
                                    <motion.div
                                        initial='hidden'
                                        custom={index}
                                        animate={hover.hover && index === hover.index && hover.index !== active ? 'visible' : 'hidden'}
                                        variants={iconVariant}
                                        children={item.icon}
                                    />
                                    <motion.div
                                        initial='hidden'
                                        animate={hover.hover && index === hover.index && hover.index !== active ? 'visible' : 'hidden'}
                                        variants={textVariant}
                                        children={item.text[language]}

                                    />
                                </motion.div>
                            </Link>
                        </motion.li>
                    )
                })
            }
            <motion.li variants={itemVariant}>
                <motion.div
                    initial='hidden'
                    animate={'visible'}
                    variants={wrapVariant}

                >
                    <LanguageDropDown />
                </motion.div>

            </motion.li>
        </motion.ul >

    )
}

export default NavDesk
