import React from 'react'
import { CheckboxGroup, DatePicker, Input, RadioButtons, Select, TextArea } from './FormikComponent';

function FormikControl(props) {

    const { control, ...rest } = props;

    switch (control) {
        case 'input':
            return <Input {...rest} />
        case 'textarea':
            return <TextArea {...rest} />
        case 'select':
            return <Select {...rest} />
        case 'radio':
            return <RadioButtons {...rest} />
        case 'checkbox':
            return <CheckboxGroup {...rest}/>
        case 'date':
            return <DatePicker {...rest} />
        default: return null

    }
}

export default FormikControl
