import { motion, useCycle } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react'
import { MenuToggle } from '../../utility/MenuToggle';
import styles from './NavMobile.module.css'
import { Link } from 'react-router-dom'
import { NavItem } from './Navbar.js';
import { useDimensions } from '../../utility/customHook';
import { LanguageMobile } from './LanguageDropDown';
import { IoIosArrowDown } from 'react-icons/io'
import { information } from '../../Information';


const navbarVariant = {
    hidden: {
        y: -100,
        opacity: 1,
        transition: {
            duration: 0.2,
        }
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.2
        }
    }
}


const sideVariant = {
    hidden: {
        opacity: 0,
        x: -500,
        transition: {
            duration: 0.3,
            ease: 'easeInOut',
            staggerChildren: 0.2,
            staggerDirection: -1,
            delay: .6
        }
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.6,
            ease: 'easeInOut',
            staggerChildren: .2,
            delayChildren: .2
        }
    }
}

const itemVariant = {
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 }
        }
    },
    hidden: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000, velocity: -100 }
        }
    },

};

function NavMobile({ language, location }) {

    const [scrolled, setScrolled] = useState(true);
    const [isOpen, toggleOpen] = useCycle(false, true);
    const containerRef = useRef(null);
    const { height } = useDimensions(containerRef);
    const [languageClick, setLanguageClick] = useState(false);
    const [active, setActive] = useState(0);


    useEffect(() => {
        location && setActive(() => {
            switch (location) {
                case '/':
                    return 0;
                case '/camere':
                    return 1;
                case '/contatti':
                    return 2;
                case '/prenota':
                    return 3;

                default:
                    return 1;
            }
        });

    }, [location])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])



    var lastScrollTop = 0;

    const handleScroll = () => {

        var st = window.pageYOffset
        if (st > lastScrollTop && st !== 0)
            setScrolled(isOpen ? true : false);

        else
            setScrolled(true);

        lastScrollTop = st <= 0 ? 0 : st;

    }

    

    return (
        <motion.div
            className={styles.container}
            custom={height}
            ref={containerRef}>

            <motion.div
                className={styles.navbar}
                initial='hidden'
                animate={scrolled ? 'visible' : 'hidden'}
                variants={navbarVariant}
            >

                {scrolled && <Link to='/'>
                    <img src={information.LOGO} className={styles.logo} alt='9' />
                </Link>}
                {scrolled && <motion.div
                    initial={false}
                    animate={isOpen ? "open" : "closed"}>
                    <MenuToggle toggle={() => toggleOpen()} className={styles.btnContainer} />
                </motion.div>}


                <motion.ul
                    className={styles.sidebar}
                    initial='hidden'
                    animate={isOpen ? 'visible' : 'hidden'}
                    variants={sideVariant}
                    height={window.innerHeight + '200px'}
                >

                    {
                        NavItem.map((item, index) => {
                            return (
                                <motion.li
                                    key={index}
                                    onClick={() => { setActive(index); toggleOpen() }}
                                    variants={itemVariant}
                                >
                                    <Link style={{ textDecoration: 'none' }} to={item.link}>
                                        <div className={`columns is-mobile is-vcentered subtitle ${active === index ? styles.sideWrapActive : styles.sideWrap}  `}>
                                            <div className={`column is-one-fifth p-0 ${styles.icon}`} children={item.icon} />
                                            <div className={`column  is-four-fifths p-2`} children={item.text[language]} />
                                        </div>
                                    </Link>
                                </motion.li>
                            )
                        })
                    }
                    <motion.li
                        variants={itemVariant}
                        className={`columns is-mobile is-vcentered`}
                        style={{ textAlign: 'center', cursor: 'pointer' }}
                        onClick={() => setLanguageClick(!languageClick)}
                    >
                        <div className={`column is-half p-0`} style={{ textAlign: 'right' }} children={<LanguageMobile click={languageClick} />} />
                        <div className={`column is-half p-0`} style={{ textAlign: 'left' }} children={<IoIosArrowDown size='20px' />} />




                    </motion.li>
                </motion.ul>



            </motion.div>
        </motion.div >
    )
}

export default NavMobile
