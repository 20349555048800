import React, { useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import styles from './Alert.module.css'

const backdrop = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
}

const modal = {
    hidden: { y: "-100vh", opacity: 0 },
    visible: {
        y: "200px",
        opacity: 1,
        transition: { delay: 0.5 }
    },
}


function Alert(props) {


    const { showAlert, setShowAlert, response, language } = props;


    useEffect(() => {
        const interval = setInterval(() => {
            setShowAlert(false)
        }, 5000);
        return () => clearInterval(interval);
    }, [response, setShowAlert]);

    return (
        <AnimatePresence exitBeforeEnter>
            { showAlert &&
                <motion.div className={styles.backdrop}
                    variants={backdrop}
                    initial='hidden'
                    animate='visible'
                    exit='hidden'
                >
                    <motion.div
                        className={styles.modal}
                        style={{ background: `var(--${props.type})` }}
                        variants={modal}
                    >
                        {response?.message[language]}
                    </motion.div>
                </motion.div>
            }
        </AnimatePresence>

    )
}

export default Alert
