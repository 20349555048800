import { IoSnow, IoTv } from 'react-icons/io5'
import { MdLocalParking } from 'react-icons/md'
import { FaCoffee, FaShower, FaWifi } from 'react-icons/fa'
import { GiResize } from 'react-icons/gi'
import { RiSafe2Fill } from 'react-icons/ri'

export const RoomsItem = [
    //COCCINELLA
    {
        img: '/images/rooms/coccinella/coccinella_1.jpg',
        name: 'Coccinella',
        video: '/videos/coccinella.mp4',
        service: [
            {
                icon: <IoSnow />,
                name: {
                    it: 'Aria Condizionata',
                    gb: 'air conditioning'
                },
            },
            {
                icon: <MdLocalParking />,
                name: {
                    it: 'Parcheggio in strada gratuito per tutta la via',
                    gb: 'Free Parking in the entire street'
                },
            },
            {
                icon: <FaCoffee />,
                name: {
                    it: 'Colazione Inclusa',
                    gb: 'Breakfast included'
                },
            },
            {
                icon: <FaShower />,
                name: {
                    it: 'Bagno Privato',
                    gb: 'Private Bathroom'
                },
            },
            {
                icon: <IoTv />,
                name: {
                    it: " smartTV 33'",
                    gb: "smartTV 33'"
                },
            },
            {
                icon: <FaWifi />,
                name: {
                    it: 'WiFi gratuito',
                    gb: 'Free WiFi'
                },
            },
            {
                icon: <GiResize />,
                name: {
                    it: '22mq',
                    gb: '22sqm'
                },
            },
        ],
        description: {
            it: "Rilassatevi soggiornando nella camera della Coccinella, la stanza più spaziosa della nostra struttura e dotata di balcone. La camera ha inoltre un ampio bagno dotato di finestra con doccia walk-in e comodi sanitari sospesi. La camera è provvista inoltre di un appendiabiti, una piccola scrivania con sedia, una smart tv da 33', un condizionatore e riscaldamento a pavimento.",
            gb: "Relax by staying in the Coccinella room, the most spacious room in our structure with a balcony. The room also has a large bathroom with a window with a walk-in shower and convenient suspended sanitary ware. The room is also equipped with a coat hanger, a small desk with a chair, a 33' smart TV, an air conditioner and underfloor heating."
        },
        gallery: [
            {
                original: '/images/rooms/coccinella/coccinella_1.jpg',
                thumbnail: '/images/rooms/coccinella/coccinella_1.jpg',
            },
            {
                original: '/images/rooms/coccinella/coccinella_2.jpg',
                thumbnail: '/images/rooms/coccinella/coccinella_2.jpg',
            },
            {
                original: '/images/rooms/coccinella/coccinella_3.jpg',
                thumbnail: '/images/rooms/coccinella/coccinella_3.jpg',
            },
            {
                original: '/images/rooms/coccinella/coccinella_4.jpg',
                thumbnail: '/images/rooms/coccinella/coccinella_4.jpg',
            },
            {
                original: '/images/rooms/coccinella/coccinella_5.jpg',
                thumbnail: '/images/rooms/coccinella/coccinella_5.jpg',
            },
            {
                original: '/images/rooms/coccinella/coccinella_6.jpg',
                thumbnail: '/images/rooms/coccinella/coccinella_6.jpg',
            },
            {
                original: '/images/rooms/coccinella/coccinella_7.jpg',
                thumbnail: '/images/rooms/coccinella/coccinella_7.jpg',
            },


        ]

    },
    //GUFO
    {
        img: '/images/rooms/gufo/gufo_1.jpg',
        name: 'Gufo',
        video: '/videos/gufo.mp4',
        service: [
            {
                icon: <IoSnow />,
                name: {
                    it: 'Aria Condizionata',
                    gb: 'air conditioning'
                },
            },
            {
                icon: <MdLocalParking />,
                name: {
                    it: 'Parcheggio in strada gratuito per tutta la via',
                    gb: 'Free Parking in the entire street'
                },
            },
            {
                icon: <FaCoffee />,
                name: {
                    it: 'Colazione Inclusa',
                    gb: 'breakfast included'
                },
            },
            {
                icon: <FaShower />,
                name: {
                    it: 'Bagno Privato',
                    gb: 'Private Bathroom'
                },
            },
            {
                icon: <IoTv />,
                name: {
                    it: "smartTV 33'",
                    gb: "smartTV 33'"
                },
            },
            {
                icon: <FaWifi />,
                name: {
                    it: 'WiFi gratuito',
                    gb: 'Free WiFi'
                },
            },
            {
                icon: <GiResize />,
                name: {
                    it: '20mq',
                    gb: '20sqm'
                },
            }


        ],
        description: {
            it: "Il soggiorno nella camera del Gufo sarà apprezzata da coloro che ricercano tranquillità: isolata dalle altre camere è affacciata sul giardino . La camera ha inoltre un bagno dotato di finestra con doccia walk-in e comodi sanitari sospesi. La camera è provvista inoltre di un appendiabiti, una piccola scrivania con sedia, una smart tv da 33', un condizionatore e riscaldamento a pavimento.", 
            gb: "The stay in the Gufo room will be appreciated by those seeking tranquility: isolated from the other rooms, it overlooks the garden. The room also has a bathroom with a window with a walk-in shower and convenient suspended sanitary ware. The room is also equipped with a coat hanger, a small desk with a chair, a 33' smart TV, an air conditioner and underfloor heating."
        },
        gallery: [
            {
                original: '/images/rooms/gufo/gufo_1.jpg',
            },
            {
                original: '/images/rooms/gufo/gufo_2.jpg',
            },
            {
                original: '/images/rooms/gufo/gufo_3.jpg',
            },
            {
                original: '/images/rooms/gufo/gufo_4.jpg',
            },
            {
                original: '/images/rooms/gufo/gufo_5.jpg',
            },
            {
                original: '/images/rooms/gufo/gufo_6.jpg',
            },
        ]
    },
    //FARFALLA
    {
        img: '/images/rooms/farfalla/farfalla_1.jpg',
        name: 'Farfalla',
        video: '/videos/farfalla.mp4',
        service: [
            {
                icon: <IoSnow />,
                name: {
                    it: 'Aria Condizionata',
                    gb: 'air conditioning'
                },
            },
            {
                icon: <MdLocalParking />,
                name: {
                    it: 'Parcheggio in strada gratuito per tutta la via',
                    gb: 'Free Parking in the entire street'
                },
            },
            {
                icon: <FaCoffee />,
                name: {
                    it: 'Colazione Inclusa',
                    gb: 'breakfast included'
                },
            },
            {
                icon: <FaShower />,
                name: {
                    it: 'Bagno Privato',
                    gb: 'Private Bathroom'
                },
            },
            {
                icon: <IoTv />,
                name: {
                    it: "smartTV 33'",
                    gb: "smartTV 33'"
                },
            },
            {
                icon: <FaWifi />,
                name: {
                    it: 'WiFi gratuito',
                    gb: 'Free WiFi'
                },
            },
            {
                icon: <GiResize />,
                name: {
                    it: '20mq',
                    gb: '20sqm'
                },
            },
        ],
        description: {
            it: "Il soggiorno nella camera della Farfalla vi ridarà la serenità persa a causa della frenetica vita lavorativa. Dotata di un balcone e di due letti singoli, trasformabili in un comodo letto matrimoniale, è l'ideale per ospitare anche una coppia di amici. La camera ha inoltre un bagno dotato di finestra con doccia walk-in e comodi sanitari sospesi. E' provvista inoltre di un appendiabiti, una piccola scrivania con sedia, una smart tv da 33', un condizionatore e riscaldamento a pavimento.",
            gb: "The stay in the Farfalla room will give you back the serenity lost due to the hectic working life. Equipped with a balcony and two single beds, which can be transformed into a comfortable double bed, it is also ideal for hosting a couple of friends. The room also has a bathroom with a window with a walk-in shower and convenient suspended sanitary ware. It is also equipped with a coat hanger, a small desk with a chair, a 33' smart TV, an air conditioner and underfloor heating.."
        },
        gallery: [
            {
                original: '/images/rooms/farfalla/farfalla_1.jpg',
            },
            {
                original: '/images/rooms/farfalla/farfalla_2.jpg',
            },
            {
                original: '/images/rooms/farfalla/farfalla_3.jpg',
            },
            {
                original: '/images/rooms/farfalla/farfalla_4.jpg',
            },
            {
                original: '/images/rooms/farfalla/farfalla_5.jpg',
            },
        ]
    },
]
