import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import styles from './EmailForm.module.css'
import FormikControl from '../FormikComponent/FormikControl';
import { multiLanguageEmail, multiLanguageError, policy } from '../../Information';
import { motion } from 'framer-motion';
import axios from 'axios';
import Alert from '../../utility/Alert';
import { RoomsItem } from '../Rooms/RoomsItem';
import Cookies from 'js-cookie'
import moment from 'moment';


const initialValues = {
    name: '',
    surname: '',
    email: '',
    message: '',
    checkIn: '',
    checkOut: '',
    room: '',
    type: '',
    numberGuest: 1,
    language: sessionStorage.getItem('language')
};


const variants = {
    open: {
        transition: { staggerChildren: 0.2, delayChildren: 0.2 }
    },
    closed: {
        transition: { staggerChildren: 0.08, staggerDirection: -1 }
    }
};

const item = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 }
        }
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 }
        }
    }
};


/* TODO :  react google rechaptcha */

function EmailForm({ language, booking, room }) {

    const [dropDownOptions, setDropDownOptions] = useState([])

    const [showAlert, setShowAlert] = useState(false);
    const [info, setInfo] = useState(null)

    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({
        name: yup.string().required(multiLanguageError.name[language]),
        surname: yup.string().required(multiLanguageError.surname[language]),
        email: yup.string()
            .email(multiLanguageError.emailForm[language])
            .required(multiLanguageError.email[language]),
        message: booking ? yup.string() : yup.string().required(multiLanguageError.message[language]),
        checkIn: booking ? yup.date().required(multiLanguageError.checkIn[language]) : yup.date(),
        checkOut: booking ? yup.date().required(multiLanguageError.checkOut[language]) : yup.date(),
    });

    const onSubmit = (values, { resetForm, setSubmitting, setErrors, setStatus }) => {

        setLoading(true);

        if (values.checkIn !== '' && values.checkOut !== '') {
            values.checkIn = moment(values.checkIn).format('DD/MM/YYYY');
            values.checkOut = moment(values.checkOut).format('DD/MM/YYYY');
            values.type = 'booking'

        }

        else
            values.type = 'info'

        axios.post('/api/sendMail', values).then(res => {
            if (res.data) {
                setShowAlert(!showAlert)
                setInfo(res.data)
                setLoading(false);
                resetForm({})
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }

        }).catch(err => {
            setStatus({ success: false })
            setSubmitting(false)
            setErrors({ submit: err.message })
        })
    };



    useEffect(() => {
        var temp = [];
        if (room) {
            temp.push({ 'key': room, 'value': room })
        }
        else {
            temp.push({ 'key': '---', 'value': '' })
        }

        RoomsItem.map(item => {
            if (temp[0]?.key !== item.name)
                temp.push({ 'key': item.name, 'value': item.name, 'label' : item.name })
            return temp
        });

        setDropDownOptions(temp);
    }, [room])

    const checkError = (e) => {

    }


    return (
        <>
            <Alert setShowAlert={setShowAlert} showAlert={showAlert} response={info} language={language} type={info?.status === 200 ? 'success' : 'wrong'} />
            <motion.ul
                variants={variants}
                className={styles.emailStyle}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize
                    validateOnBlur={true}
                    validateOnChange={true}
                >
                    {
                        formik => {
                            checkError(formik.errors);
                            return (
                                <Form>
                                    <motion.li variants={item}>
                                        <div className={styles.inputWrapper}>
                                            <FormikControl
                                                control='input'
                                                type='text'
                                                label={multiLanguageEmail.name[language]}
                                                name='name'
                                                className={`${formik.touched.name ? formik.errors.name ? styles.error : styles.correct : ''} ${styles.input} `}
                                            />
                                            <FormikControl
                                                control='input'
                                                type='text'
                                                label={multiLanguageEmail.surname[language]}
                                                name='surname'
                                                className={`${formik.touched.surname ? formik.errors.surname ? styles.error : styles.correct : ''} ${styles.input} `}
                                            />
                                        </div>
                                    </motion.li>

                                    <motion.li variants={item}>
                                        <div className={styles.inputWrapper}>
                                            <FormikControl
                                                control='input'
                                                type='email'
                                                label={multiLanguageEmail.email[language]}
                                                name='email'
                                                className={`${formik.touched.email ? formik.errors.email ? styles.error : styles.correct : ''} ${styles.input} `}
                                            />
                                            {booking && <FormikControl
                                                control='select'
                                                options={dropDownOptions}
                                                label={multiLanguageEmail.room[language]}
                                                name='room'
                                                className={`${formik.touched.room ? formik.errors.room ? styles.error : styles.correct : ''} ${styles.input} `}
                                            />}

                                            {booking && <FormikControl
                                                control='select'
                                                options={[{ key: 1, value: 1 }, { key: 2, value: 2 }]}
                                                label={multiLanguageEmail.numberGuest[language]}
                                                name='numberGuest'
                                                className={`${formik.touched.numberGuest ? formik.errors.numberGuest ? styles.error : styles.correct : ''} ${styles.input} `}
                                            />}
                                        </div>
                                    </motion.li>

                                    {
                                        booking &&
                                        <motion.li variants={item}>

                                            <div className={styles.inputWrapper}>

                                                <FormikControl
                                                    control='date'
                                                    placeholderText={multiLanguageEmail.dateFormat[language]}
                                                    label={multiLanguageEmail.checkIn[language]}
                                                    name='checkIn'
                                                    dateFormat="dd/MM/yyyy"
                                                    className={`${formik.touched.checkIn ? formik.errors.checkIn ? styles.error : styles.correct : ''} ${styles.input} `}
                                                />
                                                <FormikControl
                                                    control='date'
                                                    checkIn={formik.values.checkIn}
                                                    label={multiLanguageEmail.checkOut[language]}
                                                    name='checkOut'
                                                    disabled={formik.values.checkIn ? false : true}
                                                    placeholderText={formik.values.checkIn ? multiLanguageEmail.dateFormat[language] : multiLanguageEmail.errorDate[language]}
                                                    className={`${formik.touched.checkOut && formik.dirty ? formik.errors.checkOut ? styles.error : styles.correct : ''} ${styles.input} `}
                                                />


                                            </div>
                                        </motion.li>
                                    }

                                    <motion.li variants={item}>
                                        <FormikControl
                                            control='textarea'
                                            label='Message'
                                            name='message'
                                            className={`${formik.touched.message ? formik.errors.message ? styles.errorArea : styles.correctArea : ''} ${styles.inputArea} `}
                                        />
                                    </motion.li>

                                    <motion.li variants={item}>
                                        <button
                                            className={`button has-tooltip-arrow has-tooltip-bottom ${styles.submitButton} ${loading ? `is-loading` : ''}`}
                                            children={booking ? multiLanguageEmail.availability[language] : multiLanguageEmail.send[language]}
                                            type='submit'
                                            data-tooltip={Cookies.get('cookiePolicy') ? null : policy.error[language]}
                                            disabled={Cookies.get('cookiePolicy') ? false : true}
                                        />

                                    </motion.li>

                                </Form>

                            )
                        }
                    }
                </Formik>
            </motion.ul>
        </>
    )
}

export default EmailForm


