
import React, { useEffect, useState, useRef } from "react";
import styles from './ViewRoom.module.css';
import { useParams } from "react-router";
import { RoomsItem } from './RoomsItem';
import { BookingButton } from "../Booking/Booking";
import { multiLanguageBooking, multiLanguageRoom } from "../../Information";
import ImageGallery from 'react-image-gallery';
import MotionHoC from "../../utility/MotionHoC";

function ViewRoomComponent({ language }) {
    const { roomTitle } = useParams()

    const videoEl = useRef(null);
    const emailRef = useRef();

    const [room, setRoom] = useState({
        name: '',
        img: '',
        description: '',
        service: [],
        gallery: []
    })

    /*  const attemptPlay = () => {
         videoEl &&
             videoEl.current &&
             videoEl.current.play().catch(error => {
                 console.error("Error attempting to play", error);
             });
     }; */


    useEffect(() => {
        RoomsItem.map(item => {
            if (item.name === roomTitle) {
                setRoom(item);
            }
            return item;
        });
        //attemptPlay();
        window.scrollTo({
            behavior: "smooth",
            top: 0
        });
    }, [roomTitle]);


    return (
        <>
            <video
                className=" is-16by9"
                playsInline
                loop
                muted
                autoPlay
                alt="All the devices"
                src={room.video ? room.video : "https://stream.mux.com/6fiGM5ChLz8T66ZZiuzk1KZuIKX8zJz00/medium.mp4"}
                ref={videoEl}
            />
            <div className={styles.roomContainer}>
                <div className={`columns`}>
                    <div className={`column`}>
                        <div className={styles.title} children={roomTitle} />
                        <div className={styles.text} children={room.description[language]} />

                        <div className={`columns is-mobile is-multiline pt-6`}  >
                            <div className={`column is-full ${styles.subTitle}`}>
                                {multiLanguageRoom.service[language]}
                            </div>
                            {
                                room.service.map((item, i) => (
                                    <div className={`column is-half-mobile is-one-third-tablet is-one-quarter-desktop is-one-fifth-widescreen  is-one-fifth-fullhd  p-5 ${styles.text}`} style={{ textAlign: 'center', color: 'var(--primary)' }} key={i}>
                                        {item.icon} {'\t'}
                                        {item.name[language]}
                                    </div>
                                ))

                            }

                        </div>
                        <div style={{ textAlign: 'left' }} ref={emailRef} >
                            <div className={`subtitle ${styles.text}`} style={{ color: 'red', fontStyle: 'italic', textAlign: 'center', marginTop: '5rem' }} children={<div dangerouslySetInnerHTML={{ __html: multiLanguageBooking.price[language] }} />} />
                            <BookingButton language={language} room={room.name} />

                            {/* <div className={styles.subTitle} children={multiLanguageRoom.book[language]} />
                            <EmailForm language={language} booking={roomTitle} /> */}
                            <div className={`column is-full ${styles.subTitle}`}>
                                {multiLanguageRoom.gallery[language]}
                            </div>
                            <div >
                                <ImageGallery
                                    showIndex={true}
                                    showThumbnails={false}
                                    items={room.gallery} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>

    );
}

const ViewRoom = MotionHoC(ViewRoomComponent)

export default ViewRoom



