import React from 'react'

function InformativaPrivacy() {
    return (
        <div style={{ justifyContent: 'justify', textAlign: 'justify' }}>
            <h1 style={{ fontWeight: 900, margin: '1rem', textAlign: 'center' }}>INFORMATIVA PER IL TRATTAMENTO DEI DATI PERSONALI</h1>
            <div style={{ padding: '0 1rem' }}>
                Gentile utente, la tua privacy è importante per noi.<br />
                Siamo consapevoli di quanto siano fondamentali le modalità con cui vengono utilizzate e condivise le informazioni che ti riguardano.<br />
                Questa informativa sulla privacy descrive come raccogliamo e trattiamo i tuoi Dati Personali attraverso il presente sito.
            </div>
            <br /><br />
            <h2 style={{ fontWeight: 900, margin: '1rem', textAlign: 'center' }}>A cosa si applica questa Privacy Policy?</h2>
            <div style={{ padding: '0 1rem' }}>
                La presente Privacy Policy vale come informativa ai sensi degli artt. 13 e ss. del Regolamento (UE) 2016/679
                (General Data Protection Regulation – G.D.P.R) e si applica ai dati personali a te relativi raccolti e trattati da <span style={{ color: 'var(--primary)' }} children='Lora Giusta' />,
                con sede legale in <a href='https://www.google.com/maps/place/Via+Bosco+Don+Giovanni,+13,+22100+Como+CO/@45.7960136,9.106298,17z/data=!4m13!1m7!3m6!1s0x47869e9c8f6ad617:0x11eee33e93bc2806!2sVia+Bosco+Don+Giovanni,+13,+22100+Como+CO!3b1!8m2!3d45.7960136!4d9.1084867!3m4!1s0x47869e9c8f6ad617:0x11eee33e93bc2806!8m2!3d45.7960136!4d9.1084867' target='_blank' rel="noreferrer" style={{ color: 'var(--primary)' }} children='Don Giovanni Bosco, 13, 22100 – Como' />, nella sua qualità di Titolare del trattamento.
            </div>
            <h2 style={{ fontWeight: 900, margin: '1rem', textAlign: 'center' }}>Quali tipologia di dati trattati?</h2>
            <h3 style={{ fontWeight: 700, textAlign: 'left', marginBottom: '.5rem' }}>Dati di navigazione</h3>
            <div style={{ padding: '0 1rem' }}>
                I sistemi informatici e le procedure software preposte al funzionamento di questo sito acquisiscono alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet.<br />
                In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer e dei terminali utilizzati dagli utenti, gli indirizzi in notazione URI/URL delle risorse richieste, l’orario della richiesta ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente.
            </div>
            <h3 style={{ fontWeight: 700, textAlign: 'left', marginBottom: '.5rem', marginTop: '1rem' }}>Dati comunicati dall’utente</h3>
            <div style={{ padding: '0 1rem' }}>
                Attraverso <span style={{ color: 'var(--primary)' }} children='Lora Giusta' />  potranno essere raccolti e trattati:
                dati identificativi come nome, cognome, dati di contatto come indirizzo email e numero di telefono;
                qualsiasi altra informazione che ci fornirai spontaneamente.
            </div>
            <h2 style={{ fontWeight: 900, margin: '1rem', textAlign: 'center' }}>Come trattiamo i tuoi dati personali?</h2>
            <div style={{ padding: '0 1rem' }}>
                Osserviamo misure di sicurezza per proteggere i tuoi dati personali dai rischi di distruzione, perdita o alterazione (accidentale o illegittima) dei dati personali nonché di comunicazione o accesso non autorizzati agli stessi.<br />
            </div>
            <h2 style={{ fontWeight: 900, margin: '1rem', textAlign: 'center' }}>Con quali finalità e basi giuridiche trattiamo i tuoi dati personali?</h2>
            <div style={{ padding: '0 1rem' }}>
                Tali dati, necessari per la fruizione dei servizi web, vengono trattati allo scopo di:<br /><br />
                <li style={{paddingLeft:'1rem'}}>assicurare il corretto funzionamento dei servizi offerti.</li>
                <li style={{paddingLeft:'1rem'}}>rispondere alle comunicazioni inviate dall’utente al nostro sito.</li>
                <br />
                Le basi giuridiche che utilizziamo per trattare i suoi dati sono le seguenti:<br /><br />
                <li style={{paddingLeft:'1rem'}}>il consenso dell’interessato;</li>
                <li style={{paddingLeft:'1rem'}}>un obbligo legale;</li>
                <li style={{paddingLeft:'1rem'}}>un interesse</li>
                <br />
            </div>
            <h2 style={{ fontWeight: 900, margin: '1rem', textAlign: 'center' }}>Per quanto tempo conserviamo i tuoi dati personali?</h2>
            <div style={{ padding: '0 1rem' }}>
                I dati saranno conservati per il tempo strettamente necessario a fornirti i servizi richiesti e saranno in ogni caso eliminati a seguito di una tua richiesta, salvi ulteriori obblighi di conservazione previsti dalla legge.
            </div>
            <h2 style={{ fontWeight: 900, margin: '1rem', textAlign: 'center' }}>Con chi i tuoi dati personali sono condivisi?</h2>
            <div style={{ padding: '0 1rem' }}>
                I tuoi dati personali potranno essere resi accessibili, portati a conoscenza di o comunicati ai seguenti soggetti, i quali agiranno quali persone autorizzate al trattamento, responsabili ovvero autonomi titolari:<br />
                nostri dipendenti o collaboratori a qualsiasi titolo;
                soggetti pubblici o privati, persone fisiche o giuridiche, che svolgono attività di trattamento per nostro conto o a cui siamo tenuti a comunicare i tuoi dati personali, in forza di obblighi legali o contrattuali (quali operatori bancari, fornitori, partner commerciali e produttivi, intermediari, consulenti tecnici, aziende provinciale per i servizi sanitari, amministrazione finanziaria).
                potrai richiedere un elenco completo e aggiornato dei soggetti nominati responsabili del trattamento rivolgendoti al contatto sotto indicato.<br />
                Ti informiamo che, nel caso in cui alcuni dei soggetti di cui ci avvaliamo per trattare i tuoi dati personali siano stabiliti al di fuori dello Spazio Economico Europeo, trasferiremo i tuoi dati personali, sulla base delle clausole contrattuali standard di cui all’Art. 46 (2) (c) e (d) del GDPR.
            </div>
            <h2 style={{ fontWeight: 900, margin: '1rem', textAlign: 'center' }}>Quali sono i tuoi diritti?</h2>
            <div style={{ padding: '0 1rem' }}>
                Ai sensi degli articoli 15 e seguenti del Regolamento, hai il diritto di chiedere a <span style={{ color: 'var(--primary)' }} children='Lora Giusta' /> , in qualunque momento, l’accesso ai dati personali che ti riguardano, nonché la rettifica o la cancellazione degli stessi o l’opposizione al loro trattamento. Hai diritto, inoltre, di chiedere la limitazione del trattamento nei casi previsti dall’articolo 18 del Regolamento, nonché di ottenere in un formato strutturato, di uso comune (es. pdf) e leggibile da dispositivo automatico i dati che ti riguardano, nei casi previsti dall’articolo 20 del Regolamento.<br />
                Hai il diritto di revocare il consenso in qualsiasi momento senza pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca.<br />
                In ogni caso, hai anche il diritto di proporre reclamo all’Autorità Garante per il trattamento dei dati personali, ai sensi dell’art. 77 del Regolamento UE 679/2016.
            </div>
            <h3 style={{ fontWeight: 700, textAlign: 'left', marginBottom: '.5rem', marginTop: '1rem' }}>Link ad altri siti</h3>
            <div style={{ padding: '0 1rem' }}>
                I siti a cui reindirizziamo, inclusi (ma non solamente) i siti secondari e i fornitori di servizi di terze parti, potrebbero avere una diversa politica sulla privacy rispetto a quella esposta qui pertanto ti incoraggiamo a venirne a prenderne visione.
            </div>
            <h3 style={{ fontWeight: 700, textAlign: 'left', marginBottom: '.5rem', marginTop: '1rem' }}>Modifiche</h3>
            <div style={{ padding: '0 1rem' }}>
                La presente Privacy Policy è in vigore dal 25 agosto 2021 Il Titolare del trattamento si riserva di modificarne o semplicemente aggiornarne il contenuto, in parte o completamente, anche a causa di variazioni della normativa applicabile. Le modifiche saranno vincolanti.
            </div>
            <h3 style={{ fontWeight: 700, textAlign: 'left', marginBottom: '.5rem', marginTop: '1rem' }}>Contatti</h3>
            <div style={{ padding: '0 1rem' }}>
                Per esercitare i diritti di cui sopra o per qualunque altra richiesta non esitare a contattarci via mail a: xxx@xxx.it.
                Cercheremo di fare del nostro meglio per venire incontro alle tue esigenze e risolvere qualsiasi problema.
            </div>
            <h2 style={{ fontWeight: 900, margin: '1rem', textAlign: 'center' }}>Che cosa sono i cookie?</h2>
            <div style={{ padding: '0 1rem' }}>
                I cookie sono piccoli file di testo che i siti visitati inviano al terminale dell’utente, dove vengono memorizzati, per poi essere ritrasmessi agli stessi siti alla visita successiva.
            </div>
            <h2 style={{ fontWeight: 900, margin: '1rem', textAlign: 'center' }}>Quali cookie utilizziamo?</h2>
            <div style={{ padding: '0 1rem' }}>
                Utilizziamo cookie tecnici, sia propri che di terze parti.
                In particolare, i cookie tecnici che utilizziamo sono riconducibili alle seguenti sottocategorie:
                <br /><br />
                <li style={{paddingLeft:'1rem'}}>cookie di navigazione o di sessione, che garantiscono la normale navigazione e fruizione da parte dell’utente. Non essendo memorizzati sul terminale dell’utente, svaniscono con la chiusura del browser;</li>
                <br />

                Tali cookie, essendo di natura tecnica, non richiedono il preventivo consenso dell’utente per essere installati ed utilizzati.

            </div>
            <h2 style={{ fontWeight: 900, margin: '1rem', textAlign: 'center' }}>Quali cookie di terza parte utilizziamo?</h2>
            <div style={{ padding: '0 1rem' }}>
                Di seguito sono elencati i cookie di terze parti che utilizziamo. Per ciascuno di essi è riportato il link alla relativa informativa sul trattamento dei dati personali effettuato e sulle modalità per l’eventuale disattivazione dei cookie utilizzati. In merito ai cookie di terze parti, il titolare ha unicamente l’obbligo di inserire nella presente policy il link al sito della terza parte. È a carico di tale soggetto, invece, l’obbligo dell’informativa e dell’indicazione delle modalità per l’eventuale consenso e/o disattivazione dei cookie.
                <br /><br />
                <li style={{paddingLeft:'1rem'}}><a href='http://www.google.com/intl/it/policies/' target='_blank' rel="noreferrer" style={{ color: 'var(--primary)' }} children='Google Analytics: Informativa | Opt Out' /></li>
                <li style={{paddingLeft:'1rem'}}><a href='http://www.google.it/intl/it/policies/technologies/cookies/' target='_blank' rel="noreferrer" style={{ color: 'var(--primary)' }} children='Google Maps' /></li>
                <br />
            </div>
            <h2 style={{ fontWeight: 900, margin: '1rem', textAlign: 'center' }}>Come può essere modificata la configurazione dei cookie?</h2>
            <div style={{ padding: '0 1rem' }}>
                I cookie possono essere disabilitati dall’utente modificando le impostazioni del browser sulla base delle istruzioni rese disponibili dai relativi fornitori ai link elencati di seguito.
                <br /><br />
                <li style={{paddingLeft:'1rem'}}><a href='http://windows.microsoft.com/it-IT/windows7/Block-enable-or-allow-cookies' target='_blank' rel="noreferrer" style={{ color: 'var(--primary)' }} children='Internet Explorer' /></li>
                <li style={{paddingLeft:'1rem'}}><a href='http://support.mozilla.org/it/kb/Bloccare%20i%20cookie?redirectlocale=en-US&redirectslug=Blocking+cookies' target='_blank' rel="noreferrer" style={{ color: 'var(--primary)' }} children='Mozilla Firefox' /></li>
                <li style={{paddingLeft:'1rem'}}><a href='https://support.google.com/chrome/bin/answer.py?hl=it-IT&answer=95647&p=cpn_cookies' target='_blank' rel="noreferrer" style={{ color: 'var(--primary)' }} children='Google Chrome' /></li>
                <li style={{paddingLeft:'1rem'}}><a href='http://www.apple.com/it/privacy/use-of-cookies/' target='_blank' rel="noreferrer" style={{ color: 'var(--primary)' }} children='Apple Safari' /></li>

                <br />
                La disabilitazione di alcuni cookie tecnici potrebbe compromettere l’esperienza di navigazione e utilizzo.
            </div>
        </div>
    )
}

export default InformativaPrivacy
