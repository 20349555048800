import React, { useEffect } from 'react';
import styles from './Rooms.module.css';
import { RoomsItem } from './RoomsItem';
import { multiLanguageRoom } from '../../Information'
import RoomsCard from './RoomsCard';
import MotionHoC from '../../utility/MotionHoC';

function RoomsComponent({ language }) {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])

    return (
        <div className={styles.roomsContainer}>
            <div className={`title is-1 ${styles.roomTitle}`} children={multiLanguageRoom.roomTitle[language]} />
            <div className={`columns is-mobile is-multiline`}>

                {RoomsItem.map((item, index) =>
                    <div className='column is-full-mobile is-half-tablet is-half-desktop is-one-third-widescreen is-one-third-fullhd' key={index}>
                        <RoomsCard
                            key={index}
                            img_URL={item.img}
                            link={item.link}
                            title={item.name}
                            service={item.service}
                            language={language} />
                    </div>
                )}
            </div>
        </div>

    )
}

const Rooms = MotionHoC(RoomsComponent)

export default Rooms



/* <RoomCard
    key={index}
    img_URL={item.img}
    link={item.link}
    title={item.name}
    service={item.service}
    description={item.description[language]}
    language={language}
/> */