export const SliderHome = [
    {
        title: 'sono titolo',
        text: 'sono testo sono piu lungo per verificare che effetto fa sul css',
        image: '/images/home/slider_0.jpg'

    },
    {
        title: 'sono titolo 2',
        text: 'sono testo 2',
        image: '/images/home/slider_1.jpg'

    },
    {
        title: 'sono titolo 3',
        text: 'sono testo 3',
        image: '/images/home/slider_2.jpg'

    },
    {
        title: 'sono titolo 4 ',
        text: 'sono testo 4',
        image: '/images/home/slider_3.jpg'

    },
    {
        title: 'sono titolo 4 ',
        text: 'sono testo 4',
        image: '/images/home/slider_4.jpg'
    },
    {
        title: 'sono titolo 5 ',
        text: 'sono testo 5',
        image: '/images/home/slider_5.jpg'
    },
    {
        title: 'sono titolo 7 ',
        text: 'sono testo 7',
        image: '/images/home/slider_6.jpg'
    },
    {
        title: 'sono titolo 7 ',
        text: 'sono testo 7',
        image: '/images/home/slider_7.jpg'
    },
]

export const SliderNostri = [
    {
        image: '/images/nostri/nostri_1.jpg'
    },
    {
        image: '/images/nostri/nostri_2.jpg'
    },
]

export const SliderPosti = [
    {
        image: '/images/posti/posti_1.jpg'
    },
    {
        image: '/images/posti/posti_2.jpg'
    },
    {
        image: '/images/posti/posti_3.jpg'
    },
    {
        image: '/images/posti/posti_4.jpg'
    },
    {
        image: '/images/posti/posti_5.jpg'
    },
    {
        image: '/images/posti/posti_6.jpg'
    },
    
]

export const SliderReview = [
    {
        image: '/images/reviews/review_1.png'
    }, 
    {
        image: '/images/reviews/review_2.png'
    },
    {
        image: '/images/reviews/review_3.png'
    },
    {
        image: '/images/reviews/review_4.png'
    },
    {
        image: '/images/reviews/review_5.png'
    },
    {
        image: '/images/reviews/review_6.png'
    },
    {
        image: '/images/reviews/review_7.png'
    }
]
