import React, { useRef, useEffect } from 'react';
import EmailForm from './EmailForm';
import styles from './Contacts.module.css';
import { information, multiLanguageContact } from '../../Information';
import { IoIosMail, IoIosCall } from 'react-icons/io'
import { SiGooglemaps } from 'react-icons/si'
import MotionHoC from '../../utility/MotionHoC';
import { TiBusinessCard } from "react-icons/ti";

function ContactsComponent({ language }) {
    const emailRef = useRef();

    const handleScroll = () => {
        if (emailRef.current) {
            window.scrollTo({
                behavior: "smooth",
                top: (emailRef.current.offsetTop - 220)
            });
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])


    return (
        <>

            <div className={styles.contactContainer}>
                <div className={`title is-1 ${styles.title}`} children={multiLanguageContact.contactTitle[language]} />
                <div className={`subtitle ${styles.text}`} children={multiLanguageContact.contactText[language]} />
                <div className='columns is-mobile is-variable is-multiline' style={{ marginTop: '5vh', marginBottom: '5vh' }}>
                    <div className={`column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd`} >
                        <div className={`title is-2 ${styles.subTitle} `} children={multiLanguageContact.hoursAvaible[language]} />
                        {
                            multiLanguageContact.orari.map((item, key) =>
                                <div className={`subtitle  ${styles.text} columns is-mobile is-variable`} key={key}>
                                    <div className={'column is-one-quarter '} children={item.day[language]} style={{ color: 'var(--primary)' }} />
                                    <div className={'column'} children={item.hour[language]} style={{ textAlign: 'justify', paddingLeft: '0px' }} />
                                </div>
                            )
                        }
                    </div>
                    <div className={`column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd`} >
                        <img className={styles.logo} src={information.LOGO} alt='' />
                        <div>
                            <IoIosCall className={styles.icon} />
                            <a className={`subtitle ${styles.link}`} children={information.PHONE} href={`tel: ${information.PHONE}`} />
                        </div>
                        <div style={{ marginTop: '15px' }}>
                            <IoIosMail className={styles.icon} />
                            <span
                                className={`subtitle ${styles.link}`}
                                children={information.EMAIL}
                                onClick={handleScroll}
                            />
                        </div>
                        <div style={{ marginTop: '15px' }}>
                            <SiGooglemaps className={styles.icon} />
                            <span
                                className={`subtitle ${styles.link}`}
                                children={`${information.CITY} - ${information.CAP}, ${information.ADDRESS} `}
                                onClick={() => window.open('https://goo.gl/maps/EYMKszDzxr9RnZDU7')} />
                        </div>
                        <div style={{ marginTop: '15px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TiBusinessCard className={styles.icon} />
                                <span
                                    className={`subtitle ${styles.no_link}`}
                                    children={`P.IVA ${information.PIVA}`}
                                />
                            </div>
                            <div style={{ marginLeft: '42px' }}> {/* Aggiunge lo stesso spazio della larghezza dell'icona */}
                                <div className={`subtitle ${styles.no_link}`} style={{ fontSize: '14px', marginTop:'5px' }}>
                                    <span children={`CIR ${information.CIR}`} />
                                    <br />
                                    <span children={`CIN ${information.CIN}`} />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div style={{ marginTop: '10vh', }} ref={emailRef} >
                    <div className={`title is-2 ${styles.subTitle} `} children={multiLanguageContact.write[language]} />
                    <EmailForm language={language} />
                </div>
            </div>
        </>
    )
}

const Contacts = MotionHoC(ContactsComponent)

export default Contacts
