export const information = {
    PHONE: '+39 3516160996',
    EMAIL: 'info@lora-giusta.com',
    WEB: 'www.lora-giusta.com',
    FACEBOOK: 'https://www.facebook.com/profile.php?id=100056899291314',
    INSTAGRAM: 'https://www.instagram.com/_loragiusta_/',
    TWITTER: 'https://twitter.com/',
    TRIPADVISOR: 'https://www.tripadvisor.it/',
    AIRBNB: 'https://www.airbnb.it/rooms/52272708?guests=1&adults=1&s=67&unique_share_id=c4c15ce9-a23a-4e81-8dbe-f3cae7da5fc2',
    BOOKING: 'https://www.booking.com/hotel/it/lora-giusta-guest-house.it.html',
    ADDRESS: 'Via Don Giovanni Bosco , 13 ',
    CAP: '22100',
    CITY: 'Como',
    LOGO: '/images/logo/logo_lora_150.svg',
    CIR :  "013075-FOR-00173",
    CIN:   "IT013075B4DGU6HV99",
    PIVA: "14033701005"
}

export const multiLanguageBooking = {
    book: {
        it: "Prenota Ora",
        gb: "Book now",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    bookText: {
        it: "Richiedi subito la camera che preferisci per il tuo soggiorno. Compila il modulo qui riportato e non esitare a chiederci tutto ciò che ritieni importante per vivere il tuo soggiorno al meglio. Riceverai la nostra risposta direttamente all'indirizzo email indicato. ",
        gb: "Request the room you prefer now for your unforgettable stay. Fill out the form below and do not hesitate to ask us for anything you think is important to enjoy your holiday to the fullest. You will receive our reply directly to the email address indicated.",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    price:{
        it:`I prezzi delle camere variano dai 90€ ai 140€.`,
        gb : `Room prices range from 90€ to 140€ a day.`
    },
    priceAdvisor:{
        it : `(Il prezzo non comprende l'imposta di soggiorno del valore di 3€ a persona)`,
        gb : `(The price does not include the tourist tax of 3€ per person)`
    }
}

export const multiLanguageHome = {
    chiSiamo: {
        title: {
            it: "Chi siamo",
            gb: "who we are",
            es: "",
            fr: "",
            de: "",
            rus: ""
        },
        text: {
            it: "Siamo una nuovissima realtà a conduzione familiare.<br/> Il nostro obiettivo è offrire a chi vuole esplorare le meraviglie del lago di Como un magnifico soggiorno al giusto prezzo.<br/>  Ai nostri ospiti offriamo la possibilità di interagire con noi in 3 differenti lingue: Italiano, Inglese e Russo.<br/>  Nasciamo nel 2021 al termine di un anno molto difficile ma crediamo che ospitarvi in un luogo completamente ristrutturato, accogliente e familiare, a metà strada tra centro urbano e natura, possa farvi ritrovare il piacere di esplorare.",
            gb: "We are a brand-new family-run business, our task is to offer those who want to explore the wonders of Lake Como a magnificent stay at the right price.<br/>  We offer our guests the opportunity to interact with us in 3 different languages: Italian, English and Russian.<br/>  We are born in 2021 at the end of a very difficult year due to the pandemic.  We believe that by providing a welcoming place, completely renewed, cosy, right between the city centre and nature, and at the right price, we can give anyone the opportunity to regain the freedom to travel.",
            es: "",
            fr: "",
            de: "",
            rus: ""
        }
    },
    servizi: {
        title: {
            it: "Le Nostre Proposte",
            gb: "Our Services",
            es: "",
            fr: "",
            de: "",
            rus: ""
        },
        text: {
            it: "Crediamo che il segreto di un buon soggiorno risieda nei dettagli, per questo motivo nella nostra struttura offriamo ai cliente servizi semplici ma curati per lasciare un piacevole ricordo di noi.<br/>  Nell'intento di soddisfare varie esigenze mettiamo a vostra disposizione due camere matrimoniali con eventuale possibilità, per una di esse, di aggiungere un lettino da neonato e una stanza con due letti singoli.<br/>  Per essere certi che vi sentiate come a casa mettiamo a disposizione la nostra cucina, per scaldare pietanze, e il nostro grande tavolo da utilizzare magari per gustarvi una pizza consegnatavi dalla vicinissima pizzeria qualora non vi vada di gustare una cena in uno dei ristoranti rinomati vicino alla struttura.<br/>  Se invece foste desiderosi di vivere la città di Como e il suo lago in maniera comoda ed economica, la fermata del bus 7, che vi porterà in circa 10/15 minuti nel cuore della città, è a pochi metri dal cancello della vostra temporanea dimora.<br/>  Se siete in cerca di un percorso guidato  che vi faccia scoprire la storia e alcuni segreti della città o una guida sui sentieri del Parco la Spina, saremo lieti di accompagnarvi previa prenotazione anticipata.",
            gb: "We believe that the secret of a good stay lies in the details, for this reason in our structure we offer customers simple but well-finished services to leave a pleasant memory of us.<br/>  In order to meet various needs, we provide you with two double bedrooms with the possibility, for one of them, of adding a baby bed and a room with two single beds. <br/>  To make sure you feel at home, we offer you our kitchen which is available to heat dishes, and our large table to be used perhaps to enjoy a pizza delivered to you by the nearby pizzeria if you do not want to enjoy a dinner in one of the renowned restaurants near the structure. <br/>  If instead you are eager to live the city of Como and its lake in a convenient and economical way, the bus stop 7, which will take you to the heart of the city in about 10/15 minutes, is just a few meters from the gate of your temporary home. <br/>  If you are in looking for a guided tour that will let you discover the history and some secrets of the city or a guide on the paths of the La Spina Park, we will be happy to accompany you upon advance booking.",
            es: "",
            fr: "",
            de: "",
            rus: ""
        }
    },
    visitare: {
        title: {
            it: "Cosa visitare intorno a noi",
            gb: "What to visit around us",
            es: "",
            fr: "",
            de: "",
            rus: ""
        },
        text: {
            it: "La nostra struttura a due passi dal centro di Como, si colloca in una posizione strategica per ogni tipo di esigenza.<br/>  Lora, frazione di Como dove risiediamo, dista meno di 10 minuti di macchina dal Duomo di Como, e 5 dall'imbocco con il sentiero per la visita al Castello Baradello. I percorsi 5 e 6 del Parco la Spina sono comodamente raggiungibili a piedi in circa 10 minuti dalla struttura. <br/> Questa breve distanza garantisce ai nostri Ospiti di godere di un soggiorno rilassante senza preoccupazioni.<br/>  Altri punti d'interesse sono: Campo da golf Montorfano 6 km; Duomo di Como 3.6 km; Lungo Lago 4 km; Porta Torre 3 km; Baradello 3.3 km; Villa Olmo 6 km;  Stazione Como Lago F.N 3.9 km; Stazione San Giovanni F.N 4.4 km; Autostrada Casello di Como Grandate 5.2 km; Ponte Chiasso 8.7 km; Milano Malpensa h.0:35; Milano Linate h.0:45; Orio al Serio h.1:00",
            gb: " Our structure is a stone's throw from the centre of Como, it is located in a strategic position for every type of need.<br/>  Lora, a section of Como where we live, is less than 10 minutes by car from the Cathedral of Como, and 5 from the entrance to the path for visiting the Baradello Castle. Paths 5 and 6 of the La Spina Park can be easily reached on foot in about 10 minutes from the house.<br/>  This short distance guarantees our guests to enjoy a relaxing stay without worries. <br/>  Other points of interest are: Montorfano golf course 6 km; Como Cathedral 3.6 km; The lake 4 km; Porta Torre 3 km; Baradello Castle 3.3 km; Villa Olmo 6 km; Como Lago F.N Train Station 3.9 km; San Giovanni F.N Train Station 4.4 km; Como Grandate motorway exit 5.2 km; Chiasso bridge 8.7 km; Milan Malpensa h. 0: 35; Milan Linate h.0: 45; Orio al Serio h.1.00.",
            es: "",
            fr: "",
            de: "",
            rus: ""
        }
    },
    recensione: {
        title: {
            it: "Cosa dicono di noi",
            gb: "what they say about us",
            es: "",
            fr: "",
            de: "",
            rus: ""
        },
        text: {
            it: "Siamo una nuova realtà che ha bisogno del vostro aiuto per crescere e migliorare. Lascia una recensione del tuo soggiorno con le critiche e i punti di forza. Il tuo giudizio è la nostra fonte di ispirazione per offrire un servizio migliore ai nostri ospiti.",
            gb: "We are a new reality, help us grow and improve, leave a review on your stay with the criticisms and strengths. Your judgment is our source of inspiration to offer a better service to our guests. ",
            es: "",
            fr: "",
            de: "",
            rus: ""
        },
        writeReview: {
            it: "Scrivi una Recensione",
            gb: "Write your Review",
            es: "",
            fr: "",
            de: "",
            rus: ""
        },
        viewReview: {
            it: "Vedi le Recensione",
            gb: "View Review",
            es: "",
            fr: "",
            de: "",
            rus: ""
        }
    },
    trovaci: {
        title: {
            it: "Trovaci anche qui",
            gb: "Find us here too",
            es: "",
            fr: "",
            de: "",
            rus: ""
        },
    }
}

export const multiLanguageRoom = {
    roomNav: {
        it: "Camere",
        gb: "Rooms",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    roomTitle: {
        it: "Le Nostre Camere",
        gb: "Our Rooms",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    show: {
        it: "Visualizza Camera",
        gb: "View Room",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    availability: {
        it: "Richiedi disponibilità",
        gb: "ask for availability",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    book: {
        it: "Prenota Ora",
        gb: "Book now",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    service: {
        it: "Servizi Compresi",
        gb: "Services included",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    gallery: {
        it: "Galleria",
        gb: "Gallery",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    checkIn: {
        it: "Arrivo",
        gb: "Check-in",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    checkOut: {
        it: "Partenza",
        gb: "Check-out",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
}


export const multiLanguageContact = {
    contactTitle: {
        it: "Contatti",
        gb: "Contacts",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    contactText: {
        it: "Concediti un'esperienza unica immerso nel verde. Scopri le meraviglie che la natura ha da offrire e visita una delle citta' piu' caratteristiche d'Italia.",
        gb: "Treat yourself to a unique experience surrounded by greenery. \n Discover the wonders that nature has to offer and visit one of the most beautiful cities in the world.",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    name: {
        it: "Nome",
        gb: "Name",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    surname: {
        it: "Cognome",
        gb: "Surname",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    email: {
        it: "Email",
        gb: "Email",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    dateIn: {
        it: "Data Arrivo",
        gb: "Check-In",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    dateOut: {
        it: "Data Partenza",
        gb: "Check-Out",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    message: {
        it: "Messaggio",
        gb: "Message",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    send: {
        it: "Invia Email",
        gb: "Send Email",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    hoursAvaible: {
        it: "Orari Call Center",
        gb: "Call Center Time schedule",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    write: {
        it: "Scrivici",
        gb: "Write to us",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    orari: [
        {
            day: {
                it: "LUN : ",
                gb: "MON : ",
                es: "",
                fr: "",
                de: "",
                rus: ""
            },
            hour: {
                it: "8:00 - 12:00 / 14:00 - 21:00",
                gb: "8:00 a.m. - 12:00 a.m / 2:00 p.m - 9:00 p.m"
            }
        },
        {
            day: {
                it: "MAR : ",
                gb: "TUE : ",
                es: "",
                fr: "",
                de: "",
                rus: ""
            },
            hour: {
                it: "8:00 - 12:00 / 14:00 - 21:00",
                gb: "8:00 a.m. - 12:00 a.m / 2:00 p.m - 9:00 p.m"
            }
        },
        {
            day: {
                it: "MER : ",
                gb: "WEN : ",
                es: "",
                fr: "",
                de: "",
                rus: ""
            },
            hour: {
                it: "8:00 - 12:00 / 14:00 - 21:00",
                gb: "8:00 a.m. - 12:00 a.m / 2:00 p.m - 9:00 p.m"
            }
        },
        {
            day: {
                it: "GIO :",
                gb: "THU :",
                es: "",
                fr: "",
                de: "",
                rus: ""
            },
            hour: {
                it: "8:00 - 12:00 / 14:00 - 21:00",
                gb: "8:00 a.m. - 12:00 a.m / 2:00 p.m - 9:00 p.m"
            }
        },
        {
            day: {
                it: "VEN : ",
                gb: "FRI : ",
                es: "",
                fr: "",
                de: "",
                rus: ""
            },
            hour: {
                it: "8:00 - 12:00 / 14:00 - 21:00",
                gb: "8:00 a.m. - 12:00 a.m / 2:00 p.m - 9:00 p.m"
            }
        },
        {
            day: {
                it: "SAB : ",
                gb: "SAT : ",
                es: "",
                fr: "",
                de: "",
                rus: ""
            },
            hour: {
                it: "8:00 - 12:00 / 14:00 - 21:00",
                gb: "8:00 a.m. - 12:00 a.m / 2:00 p.m - 9:00 p.m"
            }
        },
        {
            day: {
                it: "DOM : ",
                gb: "SUN : ",
                es: "",
                fr: "",
                de: "",
                rus: ""
            },
            hour: {
                it: "8:00 - 12:00 / 14:00 - 21:00",
                gb: "8:00 a.m. - 12:00 a.m / 2:00 p.m - 9:00 p.m"
            }
        },
    ],
}

//fatto fin qui

export const multiLanguageNavbar = {
    home: {
        it: "Home",
        gb: "Home",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    room: {
        it: "Camere",
        gb: "Rooms",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    contact: {
        it: "Contatti",
        gb: "Contacts",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    booking: {
        it: "Prenota",
        gb: "Booking",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
}

export const multiLanguageEmail = {
    name: {
        it: "Nome",
        gb: "Name",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    surname: {
        it: "Cognome",
        gb: "Surname",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    email: {
        it: "Email",
        gb: "Email",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    checkIn: {
        it: "Arrivo",
        gb: "Check-in",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    checkOut: {
        it: "Partenza",
        gb: "Check-out",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    numberGuest: {
        it: "Numero di persone",
        gb: "Number of guests",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },

    message: {
        it: "Messaggio",
        gb: "Message",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    send: {
        it: "Invia Email",
        gb: "Send Email",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    availability: {
        it: "Richiedi disponibilità",
        gb: "ask for availability",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    book: {
        it: "Prenota Ora",
        gb: "Book now",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    room: {
        it: "Camera",
        gb: "Room",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    dateFormat: {
        it: "gg/mm/aaaa",
        gb: "dd/mm/yyyy",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    errorDate: {
        it: "Manca la data di arrivo",
        gb: "Insert Check-in Date",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
}

export const multiLanguageFooter = {
    homeTitle: {
        it: "Home",
        gb: "Home",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    contact: {
        it: "Contattaci",
        gb: "Contact us",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    follow: {
        it: "Seguici su ",
        gb: "Follow us",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    where: {
        it: "Dove siamo",
        gb: "where we are",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    firma: {
        it: "Creato da",
        gb: "Create by",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
}

export const multiLanguageError = {
    name: {
        it: "Il nome è obbligatorio",
        gb: "Name is Required",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    surname: {
        it: "Il Cognome è obbligatorio",
        gb: "Surname is Required",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    email: {
        it: "L'email è obbligatoria",
        gb: "Email is Required",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    emailForm: {
        it: "Il formato dell'email non è valido",
        gb: "Email format is invalid",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    message: {
        it: "Il messaggio è obbligatorio",
        gb: "Message is Required",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    checkIn: {
        it: "La data di arrivo è obbligatoria",
        gb: "Check-in is Required",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    checkOut: {
        it: "La data di partenza è obbligatoria",
        gb: "Check-out is Required",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
}

export const policy = {
    policy: {
        it: "Questo sito non utilizza cookie di profilazione.<br/>Sono utilizzati cookie di terze parti per la consultazione di mappe. <br/>Se decidi di continuare la navigazione su questo sito consideriamo accettato l'utilizzo dei cookie.<br/>Per saperne di più,  consulta l'informativa estesa sulla privacy e l'uso dei cookie.",
        gb: "Third-party cookies are used for access monitoring and map exploration.If you decide to continue browsing this website, you accept to use cookies.To learn more about cookies and read how to disable them, exploit the extensive information on privacy and use of cookies.",
        es: "",
        fr: "",
        de: "",
        rus: ""
    },
    btn: {
        it: "Accetto",
        gb: "I agree"
    },
    btnInfo: {
        it: "Maggiori Informazioni",
        gb: "More Info"
    },
    close: {
        it: "Chiudi",
        gb: "Close"
    },
    error: {
        it: "per procedere accetta i cookie",
        gb: "Please accept cookies"
    }
}