import React, { useState, useEffect } from 'react'
import "react-multi-carousel/lib/styles.css";
import Slider from './Slider';
import styles from './Home.module.css';
import { motion } from 'framer-motion';
import { multiLanguageHome } from '../../Information';
import MotionHoC from '../../utility/MotionHoC'

const homeVariant = {
    hidden: {
        opacity: 0,
        transition: {
            duration: 0.4,
            delay: 1.5,
            ease: 'easeInOut'
        }
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: .5,
            ease: 'easeInOut'
        }
    }
}

const btnVariant = {
    hidden: {
        transition: {
            duration: 1,
        }
    },
    visible: {
        scale: [.6, 1, 1, .6, .6],
        /* rotate: [0, 0, 20, -20, 0], */
        borderRadius: ["20%", "20%", "50%", "50%", "20%"],
        transition: {
            repeat: Infinity,
            duration: 1,
            repeatType: "reverse",
        }
    },
    hover: {
        scale: 1,
        rotate: 0,
        transition: {
            ease: 'easeInOut',
            duration: 1,
            type: 'spring',
            stiffness: 250
        }
    }
}


function HomeComponent() {

    const [language, setLanguage] = useState()

    useEffect(() => {
        setLanguage(() => sessionStorage.getItem('language') ? sessionStorage.getItem('language') : 'it')
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])


    return (
        <motion.div
            initial='hidden'
            animate='visible'
            variants={homeVariant}
        >
            <Slider />
            <div className={styles.homeContainer}>
                <div className={`columns  ${styles.homeWrapper} is-multiline is-vcentered is-6 is-variable`} >
                    <div className={`column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd`}>
                        <div className={`title is-1 title is-1  ${styles.subTitle}`} children={multiLanguageHome.chiSiamo.title[language]} />
                        <div className={` subtitle ${styles.text}`} children={<div dangerouslySetInnerHTML={{__html : multiLanguageHome.chiSiamo.text[language]}}/>} />
                    </div>
                    <div className={`column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd`}>
                        <figure className={`image is-1by1`}>

                            <img className={styles.image} src="/images/home/chiSiamo.jpg" alt='1' />
                        </figure>
                    </div>
                </div>

                <div className={`columns ${styles.homeWrapper} is-multiline is-vcentered is-6 is-variable`} style={{ background: '#ffa723' }} >
                    <div className={`column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd`}>
                        <Slider
                            option={'nostri'}
                            showDots={false}
                            autoPlaySpeed={5000}
                            removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                            styleOption={{ borderRadius: '50px', marginTop: '20px', maxHeight: '500px' }} />
                        {/*  <figure className={`image is-3by2 `}>
                            <img className={styles.image} src="/images/home/colazione.jpg" alt='2' />
                        </figure> */}
                    </div>
                    <div className={`column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd `}>
                        <div className={` title is-1  ${styles.subTitle}`} style={{ color: '#2a2929' }} children={multiLanguageHome.servizi.title[language]} />
                        <div className={` subtitle  ${styles.text} `} style={{ color: 'white' }} children={<div dangerouslySetInnerHTML={{__html :multiLanguageHome.servizi.text[language]}}/>} />
                    </div>
                </div>

                <div className={`columns is-multiline is-vcentered is-6 is-variable  ${styles.homeWrapper}`}  >
                    <div className={`column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd`}>
                        <div className={` title is-1  ${styles.subTitle}`} children={multiLanguageHome.visitare.title[language]} />
                        <div className={` subtitle  ${styles.text}`} children={<div dangerouslySetInnerHTML={{__html :multiLanguageHome.visitare.text[language]}}/>} />
                    </div>
                    <div className={`column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd`}>
                        <Slider
                            option={'posti'}
                            showDots={false}
                            autoPlaySpeed={5000}
                            removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                            styleOption={{ borderRadius: '50px', marginTop: '40px', maxHeight: '500px' }} />
                        {/*  <figure className={`image is-3by2 `} style={{ alignItems: 'baseline' }}>
                            <img className={styles.image} src="/images/home/visitare.jpg" alt='3' />
                        </figure> */}
                    </div>
                </div>

                <div className={`columns is-multiline is-vcentered is-6 is-variable ${styles.homeWrapper}`} style={{ background: '#5b9344', paddingBottom: 0 }} >
                    <div className={`column`}>
                        <div className={` title is-1  ${styles.subTitle}`} style={{ color: '#2a2929' }} children={multiLanguageHome.recensione.title[language]} />
                        <div className={` subtitle  ${styles.text} `} style={{ color: 'white' }} children={<div dangerouslySetInnerHTML={{__html :multiLanguageHome.recensione.text[language]}}/>} />
                    </div>
                    <div className={`column`}>
                        <motion.div
                            initial='hidden'
                            animate='visible'
                            whileHover='hover'
                            variants={btnVariant}
                            className={styles.btnContainer}>

                            <div
                                className={`button subtitle is-3 ${styles.button}`}
                                onClick={() => window.open('https://g.page/r/CfFt60OQSa_IEAg/review?rc')}
                                children={multiLanguageHome.recensione.writeReview[language]}
                            />

                        </motion.div>
                    </div>
                    <div className={`column is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd`} >
                        <Slider
                            option={'review'}
                            showDots={false}
                            autoPlaySpeed={5000}
                            removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                            swipeable={false}
                            draggable={false}
                            styleOption={{ borderRadius: '10px', marginTop: '40px', maxWidth: '720px', width: '99%', cursor: 'pointer' }}
                            onClick={() => window.open('https://www.google.com/travel/hotels/entity/CgsI8dutn4Sy0tfIARAB/reviews?g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4306835%2C4308226%2C4317915%2C4371334%2C4401769%2C4419364%2C4429192%2C4515404%2C4545890%2C4596364%2C4597339%2C4617195%2C4270859%2C4284970%2C4291517&hl=it-IT&gl=it&cs=1&ssta=1&rp=EPHbrZ-EstLXyAE4AkAASAHAAQI&ictx=1&sa=X&ved=0CAAQ5JsGahcKEwiw8pbut_zyAhUAAAAAHQAAAAAQAg&utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESABpJCisSJzIlMHg0Nzg2OWZmZjg0ODQxZTBiOjB4YzhhZjQ5OTA0M2ViNmRmMRoAEhoSFAoHCOUPEAkYDhIHCOUPEAkYDxgBMgIQACoECgAaAA')} />
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

const Home = MotionHoC(HomeComponent)

export default Home
