import { motion } from 'framer-motion'
import React from 'react'

const pageVariant = {
    initial: {
        scaleX: 0,
    },
    animate: {
        scaleX: 1,
        transition: {
            duration: .3,
            ease: "easeInOut"
        }
    },
    exit: {
        scaleX: 0,
        transition: {
            duration: .3,
            ease: "easeInOut"
        }
    }
}


function MotionHoC(Component) {
    return function HOC(props) {
        return (
            <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageVariant}
            >
                <Component {...props}/>
            </motion.div>
        )

    }
}

export default MotionHoC
