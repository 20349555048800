import './App.css';
import 'bulma/css/bulma.min.css';
import { useCookies } from 'react-cookie'
import React, { useState, useEffect } from 'react'
import Navbar from './components/Navbar/Navbar';
import { BrowserRouter as Switch, Route, useLocation } from "react-router-dom"
import Home from './components/Home/Home'
import Rooms from './components/Rooms/Rooms';
import Contacts from './components/Contacts/Contacts';
import WhatsAppWidget from 'react-whatsapp-widget'
import { AnimatePresence, motion } from 'framer-motion';
import ViewRoom from './components/Rooms/ViewRoom';
import Privacy from './utility/Privacy';
import 'react-whatsapp-widget/dist/index.css'
import Footer from './components/Footer/Footer';
import Booking from './components/Booking/Booking';
import { information } from './Information';
import { IoLogoWhatsapp } from 'react-icons/io5';

const whatsAppVariant = {
  hidden: { opacity: 1, y: 0 },
  visible: {
    y: 10,
    opacity: 1,
    transition: {
      repeat: Infinity,
      duration: 1,
      repeatType: "reverse",
    }
  }
}


function App() {

  const location = useLocation();

  const [mobile, setMobile] = useState(false);
  const [language, setLanguage] = useState();
  const [cookies, setCookie] = useCookies(['cookiePolicy']);

  const mobileView = () => {
    if (window.innerWidth <= 960) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  useEffect(() => {
    mobileView();
    if (!(sessionStorage.getItem('language'))) {
      sessionStorage.setItem('language', 'it')

    }
    setLanguage(sessionStorage.getItem('language'))

  }, []);

  window.addEventListener('resize', mobileView);

  return (
    <>
      <Navbar mobileView={mobile} language={language} />
      <AnimatePresence exitBeforeEnter >
        <Switch location={location} key={location.pathname} >
          <Route path='/' exact component={Home} />
          <Route path='/camere' render={() => <Rooms language={language} />} />
          <Route path='/room/:roomTitle' render={() => <ViewRoom language={language} />} />
          <Route path='/contatti' render={() => <Contacts language={language} />} />
          <Route path='/prenota' render={() => <Booking language={language} />} />
        </Switch>
      </AnimatePresence>

      <motion.div
        initial='hidden'
        animate={'visible'}
        className='whatsAppClass'
        whileHover={{ scale: 1, y: 0, transition: { ease: 'easeInOut', duration: .5, type: 'spring', stiffness: 250 } }}
        variants={whatsAppVariant}
      >
        {mobile && <IoLogoWhatsapp className = 'whatsAppIcon' onClick={()=>window.open(`https://wa.me/+393516160996`)}/>}
        {/* <WhatsAppWidget
          phoneNumber={'+393516160996'}
          companyName={"Lora Giusta"}
          textReplyTime={"Online dalle 8:00 - 19:00"}
          sendButton="Invia"
          message="Ciao! 👋🏼 Hai bisogno d'aiuto? Contattaci su whatsApp, informati e prenota direttamente via chat."
          style={{ background: 'transparent', margin : 0 }}
        /> */}
      </motion.div>
      <Privacy language={language} cookies={cookies.cookiePolicy} setCookie={setCookie} />
      <Footer language={language} /> 
    </>
  );
}

export default App;